<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-12">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <div class="row">
            <div class="col-md-9">
            <h3 class="mb-0">Contas a pagar</h3></div>
          <div class="col-md-3">
            <button @click="NovaParcela()" class="btn btn-info">Nova Parcela</button>
          </div>
          </div>
        </div>
   
        <div class="card-body">
          <b-table
              :fields="['finan_pedido_compra_id','descricao', 'moeda','nome_departamento','nome_centro_custo', 'vencimento', 'valor', 'acoes']"
              :items="lista_finan_contas_pagar"
              :per-page="perPage"
              :current-page="currentPage"
              id="contas_pagar-table"
              class="table table-head-custom table-vertical-center table-head-bg table-borderless"
              show-empty
              empty-text="Nenhum registro encontrado!"
          >
            <template #head(acoes)><span></span></template>
            <template #head(finan_pedido_compra_id)>
              <div>N. Pedido</div>
            </template>
            <template #head(conta_id)>
              <div>Conta</div>
            </template>
            <template #cell(conta_id)="{item}">
              <span v-if="!item.conta_id || !item.banco">--</span>
              <span v-else>{{ item.conta_id }} - {{ item.banco }}</span>
            </template>
            <template #cell(vencimento)="{item}">
              {{ item.vencimento | formatDBDateToLocale }}
            </template>
            <template #cell(valor)="{item}">
              {{ item.valor | formatMoney }}
            </template>
            <template #cell(acoes)="{item}">
              <div class="text-right">
                <button
                    
                    v-if="item.baixa === null"
            
                 @click="abrirModalFinan(item)"
                    class="btn btn-icon btn-light btn-sm mx-1 text-warning"
                    v-b-tooltip.hover title="Dar Baixa"
                >
                  <i class="far fa-list-alt text-warning"></i>
                </button>
                <span v-b-tooltip="`Recebido ${item.baixa}`">

                  <button
                      v-if="item.baixa !== null"
                @click="verComprovante(item)"
                      class="btn btn-icon btn-light btn-sm mx-1  disabled"
                  >
                    <i class="far fa-list-alt text-info"></i>
                  </button>
                      <button
                      v-if="item.baixa !== null"
                
                      class="btn btn-icon btn-light btn-sm mx-1  disabled"
                  >
                    <i class="far fa-check-circle text-success"></i>
                  </button>
                </span>
              </div>
            </template>
          </b-table>
          <b-pagination
              v-model="currentPage"
                  :total-rows="lista_finan_contas_pagar.length"
              :per-page="perPage"
              aria-controls="contas_pagar-table"
          >
          </b-pagination>
        </div>
      </div>
    </div>
           <b-modal v-model="modal_parcela.show" title="Financeiro" centered hide-footer>
      <b-overlay :show="modal_parcela.loading" rounded="sm">
        <form>
      


          <div class="form-group row">
            <div class="col-md-12">
              <label for="contrato">Valor: G$</label>
              <input
                  id="valor"
                 type="text"
                  class="form-control"
              
                v-model="modal_parcela.form.valor"
                  
              />
            </div>
          </div>

          <div class="form-group row">
            <div class="col-md-12">
              <label for="parcelas">Moeda:*</label>
              <select id="parcelas" class="form-control" v-model="modal_parcela.form.moeda_id">
                <option v-for="item in lista_finan_moedas " :key="item" :value="item.id"> {{ item.nome }}</option>
              </select>
            </div>
          </div>



          <div class="form-group row">
            <div class="col-md-12 text-right">
              <button
                  @click.prevent="submitPedido()"
                  class="btn btn-primary"
              >
                Salvar
              </button>
            </div>
          </div>
        </form>
      </b-overlay>
    </b-modal>

       <b-modal v-model="modal_finan.show" title="Baica do Titulo" centered hide-footer>
      <b-overlay :show="modal_finan.loading" rounded="sm">
        <form>
      

          <div class="form-group row">
            <div class="col-md-12">
              <label for="parcelas">Modo Pagamento:*</label>
              <select id="parcelas" class="form-control" v-model="modal_finan.form.finan_modo_pagamento_id">
                <option v-for="item in lista_finan_modo_pagamentos " :key="item.id" :value="item.id"> {{ item.nome }}</option>
              </select>
            </div>
          </div>
                 <div class="form-group row">
            <div class="col-md-12">
              <label for="contrato">Descricao: </label>
              <input
                  id="valor"
                 type="text"
                  class="form-control"
              
                v-model="modal_finan.form.descricao"
                  
              />
            </div>
          </div>



          <div class="form-group row">
            <div class="col-md-12 text-right">
              <button
                  @click.prevent="confirm()"
                  class="btn btn-primary"
              >
                Dar Baixa
              </button>
            </div>
          </div>
        </form>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/store/breadcrumbs.module";
import {fireAlert} from "@/core/mixins/alertMessageMixin";
import moneyFormat from "@/core/mixins/moneyFormat";
import dateFormat from "@/core/mixins/dateFormat";

export default {
  mixins: [fireAlert, moneyFormat, dateFormat],
  data() {
    return {
      currentPage: 1,
      perPage: 10,
          modal_parcela:{
        show:false,
        loading:false,
        
        form:{
          valor:null,
          moeda_id:null,
          perdido_id:null,
          status:1
          
        }
      },

          modal_finan:{
        show:false,
        loading:false,
        
        form:{
          finan_modo_pagamento_id:null,
          descricao:null,
          id_finan_conta_pagar:null,
       
        }
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: "Contas a Pagar"}]);
  },
  computed: {
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    lista_finan_contas_pagar() {
      return this.$store.state.financeiro.lista_finan_contas_pagar;
    },
    mensagem_alert() {
      return this.$store.state.configEmpresa.mensagem_alert;
    },
         lista_finan_moedas(){
      return this.$store.state.financeiro.lista_finan_moedas;
    },
    lista_finan_modo_pagamentos(){
      return this.$store.state.financeiro.lista_finan_modo_pagamentos;
    },
  },
  created() {
       
    this.listar_finan_contas_pagar();   

  },
  methods: {

    verComprovante(item){
      // window.open('http://example.com', '_blank');
      this.$store.dispatch("ver_comprovante",item);
         this.$router.push({name: "comprovante"});
    },
       async abrirModalFinan(value){
 this.modal_finan.show = true;
 this.modal_finan.form.id_finan_conta_pagar = value.id
 this.listar_finan_modo_pagamento()



    },
    async listar_finan_contas_pagar() {
      this.$store.dispatch("MudarPreloader", true);
      let {pedido_id} = this.$route.query
      await this.$store.dispatch("listar_finan_contas_pagar", pedido_id).finally(() => {
        this.$store.dispatch("MudarPreloader", false);
        this.$store.dispatch("atualizar", "");
      });
    },
    async confirm(value) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação dara baixa nessa conta a pagar?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            this.baixar_finan_contas_pagar(value);
          }
        },
      });
    },
    async baixar_finan_contas_pagar(value) {
 
      this.modal_finan.loading = true
      await this.$store.dispatch("baixar_finan_contas_pagar", this.modal_finan.form);
    
         this.modal_finan.loading =  false;
      this.fireAlert(this.mensagem_alert);
    this.listar_finan_contas_pagar();   
    this.modal_finan.show = false;

      
    },
     async  submitPedido(){
   
      this.$store.dispatch("MudarPreloader", true);
      await this.$store.dispatch("criarParcelaContasPagar",this.modal_parcela.form).finally(() => {
            this.modal_parcela.show = false;
            this.fireAlert(this.mensagem_alert);
                this.$store.dispatch("MudarPreloader", false);
                });
    this.listar_finan_contas_pagar();   


    },
    
    NovaParcela(){
        this.listar_finan_moeda();
       let {pedido_id} = this.$route.query
        this.modal_parcela.form.perdido_id =pedido_id
        this.modal_parcela.show= true;
    },
  


     async listar_finan_moeda() {
      this.$store.dispatch("MudarPreloader", true);
      await this.$store.dispatch("listar_finan_moeda").finally(() => {
        this.$store.dispatch("MudarPreloader", false);
        // this.$store.dispatch("atualizar", "");
      });
    },
       async listar_finan_modo_pagamento() {
      this.$store.dispatch("MudarPreloader", true);
      await this.$store.dispatch("listar_finan_modo_pagamento").finally(() => {
        this.$store.dispatch("MudarPreloader", false);
        // this.$store.dispatch("atualizar", "");
      });
    },
  }
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>